:root{
  --black: black;
  --bg: #7db8b4;
  --shadow1: 0px 13px 48px -1px rgba(0, 0, 0, 0.25);
  --shadow2:  0px 13px 46px rgba(0, 0, 0, 0.13);
  --pink: #FE956F;
  --yellow: #eee82c;
}
.App{
  display: flex;
  flex-direction: column;
  color: black;
}
::-webkit-scrollbar{
  height: 12px;
  width: 12px;
  }
  
  ::-webkit-scrollbar-track{
  background: rgb(250, 247, 247);
  border-radius: 1ex;
  }
  
  ::-webkit-scrollbar-thumb{
  background: rgb(237, 240, 241);
  border-radius: 1ex;
  }

  a{
    text-decoration: none;
    color: inherit
  }